import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import { Privacy } from "./screens/Privacy"
// import { Feedback } from "./screens/Feedback"
import { Home } from "./screens/Home"
import { Support } from "./screens/Support"


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/support">
            <Support />
          </Route>
          {/* <Route path="/feedback">
            <Feedback />
          </Route> */}
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App
