import React, { useEffect } from 'react'
import logo from '../assets/crop_logo.png'

const SUPPORT_URL = 'https://taylars.atlassian.net/servicedesk/customer/portal/2'

export function Support() {

  useEffect(() => {
    window.location.href = SUPPORT_URL
  }, [])
  
  return <div className="screen">
    <img
    src={logo} className="app-logo" alt="logo" style={{cursor: 'pointer'}}
    onClick={() => window.location.href = "/"} title="Home" />

    <div className="sm-logo-buffer" />

    <h2 style={{maxWidth: 400, width: '90%', textAlign: 'center', marginBottom: 0}}>
      Transfering you to Steppe support - if you have not been redirected, click below
    </h2>
    <h2 style={{marginTop: 5}}>
    </h2>

    <button onClick={() => window.location.href = SUPPORT_URL}>SUPPORT</button>

  </div>
}