import React from 'react'
import logo from '../assets/crop_logo.png'
import phone from '../assets/phone_trackers_preview.svg'
import '../css/Home.css'

export function Home() {
  return <div className="screen">
    <img src={logo} className="app-logo" alt="logo" />

    <div className="logo-buffer" />

    <div className="header">
      <div className="launch-text gray-text">INTRODUCING</div>
      <div className="app-name dark-gray-text">STEPPE</div>
      <div className="app-tag gray-text">YOUR DATA TRACKING TOOLKIT</div>
    </div>

    <div className="download-buffer" />

    <div
      onClick={() => window.location.href = "https://apps.apple.com/app/id1499435053"}
      className="download-button blue-bg white-text blue-hover">
        DOWNLOAD
    </div>

    <div className="download-buffer" />

    <div className="base-section light-gray-bg">
      <img src={phone} className="phone-image" alt="phone" />
    </div>
  </div>
}